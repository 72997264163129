import "unfonts.css";

import { Partytown } from "@builder.io/partytown/react";
import { ClerkApp } from "@clerk/remix";
import { useUser as useClerkUser } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { parseWithZod } from "@conform-to/zod";
import { invariantResponse } from "@epic-web/invariant";
import {
	type ActionFunctionArgs,
	HeadersFunction,
	type LinksFunction,
	type LoaderFunctionArgs,
	json,
} from "@remix-run/node";
import {
	Link,
	Links,
	Meta,
	type MetaFunction,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	useFetcher,
	useFetchers,
	useLoaderData,
	useLocation,
	useNavigate,
	useRevalidator,
	useRouteError,
	useSearchParams,
} from "@remix-run/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { NuqsAdapter } from "nuqs/adapters/remix";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";
import { $path } from "remix-routes";
import { z } from "zod";

import { cx } from "#cva.config";
import { Button } from "./components/button";
import { Isotype } from "./components/isotype";
import { OptoutForm } from "./components/optout-form";
import { EpicProgress } from "./components/progress-bar";
import { Toaster } from "./components/toaster";
import { TooltipProvider } from "./components/tooltip";
import { useRequestInfo } from "./hooks/use-request-info";
import { useToast } from "./hooks/use-toast";
import i18next from "./i18next.server";
import { getUser } from "./models/user.server";
import { getStates } from "./services/api/api/states.server";
import { queryClient } from "./services/query/config";
import appStyles from "./styles/app.css?url";
import tailwindStyles from "./styles/tailwind.css?url";
import { getHints, useHints } from "./utils/client-hints";
import { getEnv } from "./utils/env.server";
import { combineHeaders, getDomainUrl } from "./utils/misc";
import { useNonce } from "./utils/nonce-provider";
import { getNSFWSetting } from "./utils/nsfw.server";
import { getOptoutSetting } from "./utils/optout-banner.server";
import { getTheme, setTheme } from "./utils/theme.server";
import { makeTimings, time } from "./utils/timing.server";
import { getToast } from "./utils/toast.server";

export const links: LinksFunction = () => {
	return [
		{ rel: "preload", href: tailwindStyles, as: "style" },
		{ rel: "preload", href: appStyles, as: "style" },
		{ rel: "mask-icon", href: "/favicons/safari-pinned-tab.svg" },
		{
			rel: "alternate icon",
			type: "image/png",
			href: "/favicons/favicon-32x32.png",
		},
		{ rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
		{
			rel: "manifest",
			href: "/site.webmanifest",
			crossOrigin: "use-credentials",
		} as const, // necessary to make typescript happy
		{ rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
		{ rel: "stylesheet", href: tailwindStyles },
		{ rel: "stylesheet", href: appStyles },
	].filter(Boolean);
};

export async function loader(args: LoaderFunctionArgs) {
	// We need to wrap with rootAuthLoader to ensure that Clerk's auth is loaded and all the necessary data is available
	return rootAuthLoader(args, async (args) => {
		const { request } = args;
		const { userId } = request.auth;
		const timings = makeTimings("root loader");
		const user = userId
			? await time(() => getUser(userId), {
					timings,
					type: "find user",
					desc: "find user in root",
				})
			: null;
		const { toast, headers: toastHeaders } = await getToast(request);
		const locale = await i18next.getLocale(request);
		const states = await getStates(args);

		return json(
			{
				user,
				requestInfo: {
					hints: getHints(request),
					origin: getDomainUrl(request),
					path: new URL(request.url).pathname,
					userPrefs: {
						theme: getTheme(request),
						nsfw: user?.isLegalAdult ? "show" : getNSFWSetting(request),
						optoutBanner: getOptoutSetting(request) ?? "no-choice",
					},
				},
				ENV: getEnv(),
				toast,
				locale,
				states,
			},
			{
				headers: combineHeaders({ "Server-Timing": timings.toString() }, toastHeaders),
			},
		);
	});
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		"Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
	};
	return headers;
};

const title = "Timple: The Ultimate +18 AI Experience";
const description =
	"Experience your dreams with adult-themed AI roleplay. Dive into unrestricted and explicit AI conversations without any limits. Begin your journey with unfiltered AI sexting and explicit chat bots now.";
export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{
			title,
		},
		{
			name: "description",
			content: description,
		},
		{
			name: "keywords",
			content: "Timple, Timple.ai, Timple ai, timpleai, ai porn, ai hentai, ai sex, ai nudes, sext ai",
		},
		{
			name: "author",
			content: "Timple.ai",
		},
		{
			property: "og:title",
			content: title,
		},
		{
			property: "og:description",
			content: description,
		},
		{
			property: "og:url",
			content: `${data?.requestInfo.origin}${data?.requestInfo.path}`,
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			name: "twitter:card",
			content: "summary_large_image",
		},
		{
			name: "twitter:title",
			content: title,
		},
		{
			name: "twitter:description",
			content: description,
		},
		{
			name: "facebook-domain-verification",
			content: "nq5b2j4vc216kbnyadyp93miwyl3qt",
		},
	];
};

const ThemeFormSchema = z.object({
	theme: z.enum(["light", "dark"]),
});

export function ErrorBoundary() {
	const error = useRouteError();
	return (
		<html lang="en">
			<head>
				<title>Oops!</title>
				<Meta />
				<Links />
			</head>
			<body>
				<div className="flex h-screen flex-col items-center justify-center">
					<div className="flex flex-col items-center justify-center">
						<Isotype className="mb-6 h-auto w-40 text-primary" />
						<p className="font-bold text-4xl text-primary">{`${isRouteErrorResponse(error) ? error.status : "500"}`}</p>
						<p className="my-4 font-normal text-red-400 text-xl">{`${
							isRouteErrorResponse(error)
								? error.status === 404
									? "Looks like this page doesn´t exist"
									: error.statusText
								: error instanceof Error
									? error.message
									: "Unknown Error"
						}`}</p>
					</div>

					<div className="flex items-center justify-center">
						<Button>
							<Link to={$path("/")}>
								<span>Home</span>
							</Link>
						</Button>
					</div>
				</div>
				<Scripts />
			</body>
		</html>
	);
}

export const handle = {
	// In the handle export, we can add a i18n key with namespaces our route
	// will need to load. This key can be a single string or an array of strings.
	// TIP: In most cases, you should set this to your defaultNS from your i18n config
	// or if you did not set one, set it to the i18next default namespace "translation"
	i18n: "common",
};

function App() {
	const data = useLoaderData<typeof loader>();
	const nonce = useNonce();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const { toast } = useToast();
	const { user: clerkUser } = useClerkUser();
	const { revalidate } = useRevalidator();
	const [searchParams] = useSearchParams();
	const { i18n } = useTranslation();
	const fetcher = useFetcher();

	// This hook will change the i18n instance language to the current locale
	// detected by the loader, this way, when we do something to change the
	// language, this locale will change and i18next will load the correct
	// translation files
	useChangeLanguage(data.locale);

	useEffect(() => {
		/**
		 * - If user is logged in but has no profile, redirect to onboarding
		 */
		if (data.user && !data.user.profile && location.pathname !== $path("/onboarding")) {
			setTimeout(() => {
				navigate($path("/onboarding"), { replace: true });
			}, 0);
		}
	}, [data.user, location.pathname, navigate]);

	/**
	 * - If user is not logged in, revalidate the page every 3 seconds
	 * - This is to ensure that the user is logged in before the page is rendered
	 */
	useEffect(() => {
		let timeout: NodeJS.Timeout;

		if (!clerkUser) {
			timeout = setTimeout(() => {
				revalidate();
			}, 3000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [clerkUser]);

	useEffect(() => {
		if (searchParams.get("action") === "login" || searchParams.get("action") === "onboarding-finished") {
			setTimeout(() => revalidate(), 0);
		}
	}, [searchParams, navigate]);

	useEffect(() => {
		if (searchParams.get("action") === "login") {
			const data = new FormData();
			data.append("value", "hide");
			fetcher.submit(data, {
				method: "POST",
				action: $path("/resources/nsfw"),
			});

			setTimeout(() => navigate(".", { replace: true }), 0);
		} else if (searchParams.get("action") === "logout") {
			const data = new FormData();
			data.append("value", "hide");
			fetcher.submit(data, {
				method: "POST",
				action: $path("/resources/nsfw"),
			});

			setTimeout(() => navigate(".", { replace: true }), 0);
		}
	}, [searchParams, navigate]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: we don't want to exhaustively check all the possible values of the theme
	useEffect(() => {
		const serverToast = data.toast;
		if (serverToast) {
			setTimeout(() => {
				toast({
					title: serverToast.title,
					description: serverToast.description,
					variant: serverToast.type,
					action: serverToast.action,
				});
			}, 0);
		}
	}, [data.toast]);

	useEffect(() => {
		const optoutBanner = data.requestInfo.userPrefs.optoutBanner;
		if (optoutBanner === "no-choice") {
			setTimeout(() => {
				toast({
					title: "We value your privacy",
					description: (
						<>
							We use cookies to personalize your experience. By clicking "Accept All", you agree to{" "}
							<a href="/legal" className="underline">
								our use of cookies
							</a>
							.
						</>
					),
					duration: Infinity,
					dismissable: false,
					action: (
						<div className="grid grid-cols-12 items-center gap-2">
							<OptoutForm value="rejected" buttonVariant="secondary" buttonLabel="Reject all" />
							<OptoutForm value="accepted" buttonVariant="default" />
						</div>
					),
				});
			}, 0);
		}
	}, [data.toast]);

	return (
		<html className={cx("antialiased transition duration-500", theme)} lang={data.locale} dir={i18n.dir()}>
			<head>
				<meta charSet="utf-8" />
				<meta content="width=device-width, initial-scale=1" name="viewport" />
				<Partytown debug={ENV.MODE !== "production"} forward={["dataLayer.push"]} nonce={nonce} />
				<Meta />
				<Links />
			</head>
			<body className="max-h-screen bg-gray-50 dark:bg-[#12121D]" suppressHydrationWarning>
				<Outlet />

				<Toaster />
				<EpicProgress />

				<script
					// biome-ignore lint/security/noDangerouslySetInnerHtml: is not user-controlled, it's comming from the server
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(data.ENV)}`,
					}}
					nonce={nonce}
				/>
				<script
					// biome-ignore lint/security/noDangerouslySetInnerHtml: this script comes from GTM
					dangerouslySetInnerHTML={{
						__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WRTK8VQJ');`,
					}}
					nonce={nonce}
					type="text/partytown"
				/>
				{/* Google Tag Manager (noscript) */}
				<noscript>
					<iframe
						height="0"
						src="https://www.googletagmanager.com/ns.html?id=GTM-WRTK8VQJ"
						style={{
							display: "none",
							visibility: "hidden",
						}}
						title="Google Tag Manager"
						width="0"
					/>
				</noscript>
				{/* End Google Tag Manager (noscript) */}
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	);
}

function AppWithProviders() {
	return (
		<NuqsAdapter>
			<QueryClientProvider client={queryClient}>
				<TooltipProvider>
					<App />
				</TooltipProvider>
			</QueryClientProvider>
		</NuqsAdapter>
	);
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData();
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	});

	invariantResponse(submission.status === "success", "Invalid theme received");

	const { theme } = submission.value;

	const responseInit = {
		headers: { "set-cookie": setTheme(theme) },
	};

	return json({ result: submission.reply() }, responseInit);
}

export default ClerkApp(AppWithProviders, {
	signInFallbackRedirectUrl: $path("/"),
	signUpFallbackRedirectUrl: $path("/"),
	afterSignOutUrl: $path("/"),
	signInUrl: "/login",
	signUpUrl: "/signup",
});

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints();
	const requestInfo = useRequestInfo();
	const optimisticMode = useOptimisticThemeMode();

	// Predeterminar el tema oscuro si no hay preferencia guardada
	if (!requestInfo.userPrefs.theme) {
		return "dark";
	}

	if (optimisticMode) {
		return optimisticMode === "light" ? hints.theme : optimisticMode;
	}

	return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers();
	const themeFetcher = fetchers.find((f) => f.formAction === "/");

	if (themeFetcher?.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		});

		if (submission.status === "success") {
			return submission.value.theme;
		}
	}
}
